import React, { useState } from 'react';
import { Box, Button, Divider, Paper } from '@mui/material';
import { Link } from "react-router-dom";
import SiteSettings from './components/SiteSettings';
import AppSettings from './components/AppSettings';
import ShareSettings from './components/ShareSettings';
import TranslationSettings from './components/TranslationSettings';
import GlobalSettings from './components/GlobalSettings';
import TextToSpeechSettings from './components/TextToSpeechSettings';
import PushNotification from './components/PushNotification';
import EmailSettings from './components/EmailSettings';
import Maintenance from './components/Maintenance';
import LiveNews from './components/LiveNews';
import AdmobSettings from './components/AdmobSettings';
import FacebookAds from './components/FacebookAds';



const AllSettings = () => {
  const [activeSetting, setActiveSetting] = useState('Site Settings');

  // List of settings items
  const settingsItems = [
    'Site Settings',
    'App Settings',
    'Share Settings',
    'Translation Settings',
    'Global Settings',
    'Text to Speech Settings',
    'Push Notification',
    'Email Settings',
    'Maintenance',
    'Live News & E-news',
    'Admob Settings',
    'Facebook Ads',
  ];

  // Function to render the component based on the active setting
  const renderActiveSetting = () => {
    switch (activeSetting) {
      case 'Site Settings':
        return <SiteSettings />;
      case 'App Settings':
        return <AppSettings />;
      case 'Share Settings':
        return <ShareSettings />;
      case 'Translation Settings':
        return <TranslationSettings />;
      case 'Global Settings':
        return <GlobalSettings />;
      case 'Text to Speech Settings':
        return <TextToSpeechSettings />;
      case 'Push Notification':
        return <PushNotification />;
      case 'Email Settings':
        return <EmailSettings />;
      case 'Maintenance':
        return <Maintenance />;
      case 'Live News & E-news':
        return <LiveNews />;
      case 'Admob Settings':
        return <AdmobSettings />;
      case 'Facebook Ads':
        return <FacebookAds />;
      default:
        return null;
    }
  };

  return (
    <>
    <div style={{ margin: "16px 0", fontSize: "26px", paddingBottom: 10 }}>
    <Link to="/dashboard" style={{ color: "#000" }}>
      Dashboard
    </Link> / All Settings
  </div>
    <Paper elevation={3} style={{ padding: '20px', width: '95%' }}>
      
      {/* Header with all items */}
      <Box style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '20px' }}>
        {settingsItems.map((item, index) => (
          <Button
            key={index}
            sx={{
              marginRight: '10px',
              marginBottom: '10px',
              borderRadius: 0, // Remove border radius
              color: '#84818F',
              borderBottom: activeSetting === item ? '3px solid #1F09FF' : 'none',
              '&:hover': {
                borderBottom: '2px solid #1F09FF',
              },
            }}
            onClick={() => setActiveSetting(item)}
          >
            {item}
          </Button>
        ))}
      </Box>

      {/* Horizontal line */}
      <Divider style={{ marginBottom: '20px' }} />

      {/* Active item content */}
      <Box>
        {/* <Typography variant="h6" gutterBottom>
          {activeSetting}
        </Typography> */}
        {renderActiveSetting()}
      </Box>
    </Paper>
    </>
  );
};

export default AllSettings;
