import React from 'react'
import { Box, Button, Typography, Switch } from '@mui/material';

export default function TextToSpeechSettings() {
  const [enabled, setEnabled] = React.useState(true);

  const handleSwitchChange = (event) => {
    setEnabled(event.target.checked);
  };

  return (
    <>
      <Box mt={4} display="flex" alignItems="center">
     <Switch
          checked={enabled}
          onChange={handleSwitchChange}
          color="primary"
        />
         <Typography variant="body1" sx={{ color: "#84818F", mr: 2 }}>
          Voice Enable
        </Typography>
      </Box>
        {/* Save and Back Buttons */}
        <Box mt={4} display="flex" justifyContent="left">
        <Button variant="contained" sx={{background: "#7367F0", marginRight:3}}>
          Save
        </Button>
        <Button variant="outlined" color="secondary">
          Back
        </Button>
      </Box>
    </>
  )
}
