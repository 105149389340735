import React from 'react';
import { Box, Grid, TextField, Typography , Switch, Button} from '@mui/material';

export default function AdmobSettings() {
  const [enabled, setEnabled] = React.useState(true);

  const handleSwitchChange = (event) => {
    setEnabled(event.target.checked);
  };
  return (
    <>
     <Box mt={1} display="flex" alignItems="center">
              <Switch
                checked={enabled}
                onChange={handleSwitchChange}
                color="primary"
              />
              <Typography variant="body1" sx={{ color: "#84818F", mr: 2 }}>
                Enable Ads
              </Typography>
            </Box>
      <Grid container spacing={2}>
        {/* Admob Banner ID for Android */}
        <Grid item xs={12} md={6}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Admob Banner ID (Android)
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="ca-app-pub-3940256099942544/921458974"
          />
        </Grid>

        {/* Admob Interstitial ID for Android */}
        <Grid item xs={12} md={6}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Admob Interstitial ID (Android)
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="ca-app-pub-3940256099942544/8691691433"
          />
        </Grid>

        {/* Admob Banner ID for iOS */}
        <Grid item xs={12} md={6}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Admob Banner ID (iOS)
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="ca-app-pub-3940256099942544/921458974"
          />
        </Grid>

        {/* Admob Interstitial ID for iOS */}
        <Grid item xs={12} md={6}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Admob Interstitial ID (iOS)
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="ca-app-pub-3940256099942544/8691691433"
          />
        </Grid>

        {/* Admob Frequency */}
        <Grid item xs={12} md={6}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Admob Frequency
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="2"
          />
        </Grid>
      </Grid>
        {/* Save and Back Buttons */}
        <Box mt={4} display="flex" justifyContent="left">
        <Button variant="contained" sx={{ background: "#7367F0", marginRight: 3 }}>
          Save
        </Button>
        <Button variant="outlined" color="secondary">
          Back
        </Button>
      </Box>
    </>
  );
}
