import React from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';

const SiteSettings = () => {
  return (
    <>
               {/* Input fields */}
      <Grid container spacing={2}>
        {/* First row of fields */}
        <Grid item xs={12} md={4}>
          <Typography variant="body1" gutterBottom sx={{color:"#84818F"}}>
            Website Admin Name
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Enter website admin name"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="body1" gutterBottom sx={{color:"#84818F"}}>
            Email From
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Enter email from address"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="body1" gutterBottom sx={{color:"#84818F"}}>
            Powered By
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Enter powered by text"
          />
        </Grid>

        {/* Second row of fields */}
        <Grid item xs={12} md={4}>
          <Typography variant="body1" gutterBottom sx={{color:"#84818F"}}>
            Preferred Site Language
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Enter preferred site language"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="body1" gutterBottom sx={{color:"#84818F"}}>
            News API Key
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Enter news API key"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="body1" gutterBottom sx={{color:"#84818F"}}>
            Google Analytics Code
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            multiline
            rows={2}
            placeholder="Enter Google Analytics code"
          />
        </Grid>
      </Grid>

    

      {/* Logo Upload Section */}
      <Box display="flex" flexDirection="column" gap={2} mb={2} sx={{marginTop:6}}>
        {/* <Typography variant="h6">Upload Logos (Resolution: 512x512)</Typography> */}

        {/* Website Logo */}
        {/* Logo Upload Section */}
      <Box display="flex" flexDirection="column" gap={2} mb={2}>

        {/* Website Logo */}
        <Box display="flex" flexDirection="column">
          <Typography variant="body1">Website Logo</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <img
              src="/download.jpeg"
              alt="logo"
              width="70"
              height="70"
              style={{ borderRadius: '8px' }}
            />
            <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
              <Button variant="contained" sx={{backgroundColor:"#1F09FF"}} component="label">
                Upload Website Logo
                <input type="file" hidden />
              </Button>
              <Typography sx={{ fontSize: 16 }}>Resolution: 512x512</Typography>
            </Box>
          </Box>
        </Box>

        {/* Website Admin Logo */}
        <Box display="flex" flexDirection="column">
          <Typography variant="body1">Website Admin Logo</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <img
              src="/download.jpeg"
              alt="admin logo"
              width="70"
              height="70"
              style={{ borderRadius: '8px' }}
            />
            <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
              <Button variant="contained"sx={{backgroundColor:"#1F09FF"}} component="label">
                Upload Website Admin Logo
                <input type="file" hidden />
              </Button>
              <Typography sx={{ fontSize: 16 }}>Resolution: 512x512</Typography>
            </Box>
          </Box>
        </Box>

        {/* Favicon */}
        <Box display="flex" flexDirection="column">
          <Typography variant="body1">Website Favicon</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <img
              src="/download.jpeg"
              alt="favicon"
              width="70"
              height="70"
              style={{ borderRadius: '8px' }}
            />
            <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
              <Button variant="contained" sx={{backgroundColor:"#1F09FF"}} component="label">
                Upload Website Favicon
                <input type="file" hidden />
              </Button>
              <Typography sx={{ fontSize: 16 }}>Resolution: 512x512</Typography>
            </Box>
          </Box>
          </Box>
          </Box>
       
       
      </Box>

      {/* Save and Back Buttons */}
      <Box mt={4} display="flex" justifyContent="left">
        <Button variant="contained" sx={{background: "#7367F0", marginRight:3}}>
          Save
        </Button>
        <Button variant="outlined" color="secondary">
          Back
        </Button>
      </Box>
    </>
  );
};

export default SiteSettings;
