import React from 'react'
import { Box, Button, Switch , Grid, TextField, Typography} from '@mui/material';

export default function Maintenance() {
  const [enabled, setEnabled] = React.useState(true);

  const handleSwitchChange = (event) => {
    setEnabled(event.target.checked);
  };

  return (
    <>
      <Box mt={4} display="flex" alignItems="center">
     <Switch
          checked={enabled}
          onChange={handleSwitchChange}
          color="primary"
        />
         <Typography variant="body1" sx={{ color: "#84818F", mr: 2 }}>
          Enable Maintainace mood
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {/* Maintainace title */}
        <Grid item xs={12} md={6}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Maintainace title
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Server under maintainace"
          />
        </Grid>

        {/* One Signal app id */}
        <Grid item xs={12} md={6}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
          Maintainace Short text
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="we are sorry for trouble shooting"
          />
        </Grid>
      </Grid>
        {/* Save and Back Buttons */}
        <Box mt={4} display="flex" justifyContent="left">
        <Button variant="contained" sx={{background: "#7367F0", marginRight:3}}>
          Save
        </Button>
        <Button variant="outlined" color="secondary">
          Back
        </Button>
      </Box>
    </>
  )
}
