import React from 'react'
import { Box, Button, Grid, TextField, Typography } from '@mui/material';

export default function TranslationSettings() {
  return (
    <>
      <Grid container spacing={2}>
        {/* Chat gpt Api Key */}
        <Grid item xs={12} md={4}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Chat gpt Api Key
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Enter Chat gpt Api Key"
          />
        </Grid>

        {/* Google Translation Api Key */}
        <Grid item xs={12} md={4}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
          Google Translation Api Key
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Google Translation Api Key"
          />
        </Grid>
      </Grid>
        {/* Save and Back Buttons */}
        <Box mt={4} display="flex" justifyContent="left">
        <Button variant="contained" sx={{background: "#7367F0", marginRight:3}}>
          Save
        </Button>
        <Button variant="outlined" color="secondary">
          Back
        </Button>
      </Box>

    </>
  )
}
