import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { Link } from "react-router-dom";

const categoriesData = [
  {
    id: 1,
    image: "/download.jpeg",
    mainCategory: "Technology",
    name: "Web Development",
    totalBlogs: 120,
  },
  {
    id: 2,
    image: "/download.jpeg",
    mainCategory: "Health",
    name: "Fitness",
    totalBlogs: 80,
  },
  // Add more rows as needed
];

const Categories = () => {
  return (
    <div style={{ width: "100%", marginTop: "50px", padding: "0" }}>
      {/* Breadcrumb Section */}
      <div style={{ margin: "16px 0 ", fontSize: "26px" , paddingBottom:10}}>
        <Link to="/dashboard" style={{color: "#000" }}>
          Dashboard
        </Link> / Category List
      </div>

      {/* Filter Section */}
      <div
        style={{
          marginBottom: "20px",
          padding: "18px",
          backgroundColor: "#fff",
          borderRadius: "8px",
        }}
      >
        <strong style={{ fontSize:20}}>Filter</strong>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            marginTop: "10px",
          }}
        >
          {/* Search Name Field */}
          <TextField
            label="Search Name"
            variant="outlined"
            size="small"
            style={{ width: "25%" }}
          />

          {/* Select Feature Field */}
          <FormControl variant="outlined" size="small" style={{ width: "25%" }}>
            <InputLabel>Select Feature</InputLabel>
            <Select label="Select Feature">
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Feature1">Feature 1</MenuItem>
              <MenuItem value="Feature2">Feature 2</MenuItem>
            </Select>
          </FormControl>

          {/* Select Status Field */}
          <FormControl variant="outlined" size="small" style={{ width: "25%" }}>
            <InputLabel>Select Status</InputLabel>
            <Select label="Select Status">
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </Select>
          </FormControl>

          {/* Search and Reset Buttons */}
          <div style={{ display: "flex", gap: "10px" }}>
            <Button variant="contained" color="primary" size="small">
              Search
            </Button>
            <Button variant="outlined" color="secondary" size="small">
              Reset
            </Button>
          </div>
        </div>
      </div>

      {/* Table Section */}
      <div style={{ width: "100%", marginTop: "20px", padding: "0" }}>
      <div
        style={{
          backgroundColor: "#f9f9f9",
          border: "1px solid #ddd",
          borderRadius: "8px",
        }}
      >
        <h2 style={{ margin: "0", padding: "25px" }}>Most Selected Categories</h2>
        <TableContainer component={Paper} style={{ width: "100%", margin: "0" }}>
          <Table aria-label="most selected categories">
            <TableHead>
              <TableRow>
                <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>ID</TableCell>
                <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Image</TableCell>
                <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Main Category</TableCell>
                <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Name</TableCell>
                <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Total Blogs</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categoriesData.map((category, index) => (
                <TableRow key={index}>
                  <TableCell>{category.id}</TableCell>
                  <TableCell>
                    <img
                      src={category.image}
                      alt={category.name}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%", // Circle styling
                      }}
                    />
                  </TableCell>
                  <TableCell>{category.mainCategory}</TableCell>
                  <TableCell>{category.name}</TableCell>
                  <TableCell>{category.totalBlogs}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
    </div>
  );
};

export default Categories;
