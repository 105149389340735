import React from 'react';
import { Box, Button, Grid, TextField, Typography, Switch } from '@mui/material';

export default function ShareSettings() {
  const [enabled, setEnabled] = React.useState(true);

  const handleSwitchChange = (event) => {
    setEnabled(event.target.checked);
  };

  return (
    <>
      <Grid container spacing={2}>
        {/* Android Schema Field */}
        <Grid item xs={12} md={4}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Android Schema
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Enter Android Schema"
          />
        </Grid>

        {/* Playstore URL Field */}
        <Grid item xs={12} md={4}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Playstore URL
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Enter Playstore URL"
          />
        </Grid>

        {/* iOS Schema Field */}
        <Grid item xs={12} md={4}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            iOS Schema
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Enter iOS Schema"
          />
        </Grid>

        {/* Appstore URL Field */}
        <Grid item xs={12} md={4}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Appstore URL
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Enter Appstore URL"
          />
        </Grid>
      </Grid>

      {/* Switch for Enabling Share Settings */}
      <Box mt={4} display="flex" alignItems="center">
       
        <Switch
          checked={enabled}
          onChange={handleSwitchChange}
          color="primary"
        />
         <Typography variant="body1" sx={{ color: "#84818F", mr: 2 }}>
          Enable Share Settings
        </Typography>
      </Box>
        {/* Save and Back Buttons */}
        <Box mt={4} display="flex" justifyContent="left">
        <Button variant="contained" sx={{background: "#7367F0", marginRight:3}}>
          Save
        </Button>
        <Button variant="outlined" color="secondary">
          Back
        </Button>
      </Box>
    </>
  );
}
