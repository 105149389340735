import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

const visibilityData = [
  { id: 1, name: "Home Page", visibility: "Visible", status: "Active" },
  { id: 2, name: "About Page", visibility: "Hidden", status: "Inactive" },
  // Add more visibility items as needed
];

const Visibility = () => {
  const [filters, setFilters] = useState({
    name: "",
    status: "",
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleSearch = () => {
    // Implement search functionality based on filters
  };

  const handleReset = () => {
    setFilters({
      name: "",
      status: "",
    });
  };

  // Filter data based on the selected filters
  const filteredData = visibilityData.filter((item) =>
    (filters.name === "" || item.name.toLowerCase().includes(filters.name.toLowerCase())) &&
    (filters.status === "" || item.status === filters.status)
  );

  return (
    <div style={{ width: "100%", marginTop: "50px", padding: "0" }}>
      <div style={{ margin: "16px 0", fontSize: "26px", paddingBottom: 10 }}>
        <Link to="/dashboard" style={{ color: "#000" }}>
          Dashboard
        </Link> / Visibility List
      </div>
      {/* Filter Section */}
      <div
        style={{
          marginBottom: "20px",
          padding: "18px",
          backgroundColor: "#fff",
          borderRadius: "8px",
        }}
      >
        <strong style={{ fontSize: 20 }}>Filter</strong>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            marginTop: "10px",
          }}
        >
          {/* Search Name */}
          <TextField
            label="Search Name"
            name="name"
            value={filters.name}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            style={{ flex: "1 1 24%", minWidth: "200px" }}
          />

          {/* Select Status */}
          <FormControl variant="outlined" size="small" style={{ flex: "1 1 24%", minWidth: "200px" }}>
            <InputLabel>Select Status</InputLabel>
            <Select
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              label="Select Status"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </Select>
          </FormControl>

          {/* Buttons */}
          <div style={{ flex: "1 1 24%", minWidth: "200px" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSearch}
              style={{ marginRight: 10 }}
            >
              Search
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </div>
      </div>

      {/* Table Section */}
      <div style={{ width: "100%", marginTop: "50px", padding: "0" }}>
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid #ddd",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h5" style={{ margin: "0", padding: "25px" }}>
            Visibility List
          </Typography>
          <TableContainer component={Paper} style={{ width: "100%", margin: "0" }}>
            <Table aria-label="visibility list">
              <TableHead>
                <TableRow>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>ID</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Visibility</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.length > 0 ? (
                  filteredData.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.visibility}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2} style={{ textAlign: "center", color: "red" }}>
                      No results found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default Visibility;
