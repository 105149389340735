import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,

} from "@mui/material";
import { Link } from "react-router-dom";

const newsData = [
  // {
  //   id: 1,
  //   image: "/path/to/image1.jpg",
  //   title: "New Technology Trends in 2024",
  //   source: "TechNews",
  //   language: "English",
  //   publishDate: "2024-09-01",
  //   modifiedDate: "2024-09-02"
  // },
  // {
  //   id: 2,
  //   image: "/path/to/image2.jpg",
  //   title: "Health Benefits of a Balanced Diet",
  //   source: "HealthDaily",
  //   language: "Spanish",
  //   publishDate: "2024-08-25",
  //   modifiedDate: "2024-08-26"
  // },
  // Add more news items as needed
];

const NewsAPI = () => {
  const [filters, setFilters] = useState({
    keyword: "",
    source: "",
    language: "",
    publishDate: "",
    modifiedDate: ""
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleSearch = () => {
    // Implement search functionality based on filters
  };

  const handleReset = () => {
    setFilters({
      keyword: "",
      source: "",
      language: "",
      publishDate: "",
      modifiedDate: ""
    });
  };

  // Filter data based on the selected filters
  const filteredData = newsData.filter(news =>
    (filters.keyword === "" || news.title.toLowerCase().includes(filters.keyword.toLowerCase())) &&
    (filters.source === "" || news.source === filters.source) &&
    (filters.language === "" || news.language === filters.language) &&
    (filters.publishDate === "" || news.publishDate === filters.publishDate) &&
    (filters.modifiedDate === "" || news.modifiedDate === filters.modifiedDate)
  );

  return (
    <div style={{ width: "100%", marginTop: "50px", padding: "0",minHeight: "110vh"  }}>
      <div style={{ margin: "16px 0", fontSize: "26px", paddingBottom: 10 }}>
        <Link to="/dashboard" style={{ color: "#000" }}>
          Dashboard
        </Link> / News API List
      </div>
      {/* Filter Section */}
      <div
        style={{
          marginBottom: "20px",
          padding: "18px",
          backgroundColor: "#fff",
          borderRadius: "8px"
        }}
      >
        <strong style={{ fontSize: 20 }}>Filter</strong>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            marginTop: "10px"
          }}
        >
          {/* Keyword */}
          <TextField
            label="Keyword"
            name="keyword"
            value={filters.keyword}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            style={{ flex: "1 1 24%", minWidth: "200px" }}
          />

          {/* Select Source */}
          <FormControl variant="outlined" size="small" style={{ flex: "1 1 24%", minWidth: "200px" }}>
            <InputLabel>Select Source</InputLabel>
            <Select
              name="source"
              value={filters.source}
              onChange={handleFilterChange}
              label="Select Source"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="TechNews">TechNews</MenuItem>
              <MenuItem value="HealthDaily">HealthDaily</MenuItem>
              {/* Add more sources as needed */}
            </Select>
          </FormControl>

          {/* Select Language */}
          <FormControl variant="outlined" size="small" style={{ flex: "1 1 24%", minWidth: "200px" }}>
            <InputLabel>Select Language</InputLabel>
            <Select
              name="language"
              value={filters.language}
              onChange={handleFilterChange}
              label="Select Language"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="English">English</MenuItem>
              <MenuItem value="Spanish">Spanish</MenuItem>
              {/* Add more languages as needed */}
            </Select>
          </FormControl>

          {/* Publish Date */}
          <TextField
            label="Publish Date"
            name="publishDate"
            type="date"
            value={filters.publishDate}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            style={{ flex: "1 1 24%", minWidth: "200px" }}
            InputLabelProps={{ shrink: true }}
          />

          {/* Modified Date */}
          <TextField
            label="Modified Date"
            name="modifiedDate"
            type="date"
            value={filters.modifiedDate}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            style={{ flex: "1 1 24%", minWidth: "200px" }}
            InputLabelProps={{ shrink: true }}
          />

          {/* Buttons */}
          <div style={{ flex: "1 1 24%", minWidth: "200px" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSearch}
              style={{ marginRight: 10 }}
            >
              Search
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </div>
      </div>

      {/* Table Section */}
      <div style={{ width: "100%", marginTop: "50px", padding: "0" }}>
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid #ddd",
            borderRadius: "8px"
          }}
        >
          <Typography variant="h5" style={{ margin: "0", padding: "25px" }}>
            News API List
          </Typography>
          <TableContainer component={Paper} style={{ width: "100%", margin: "0" }}>
            <Table aria-label="news api list">
              <TableHead>
                <TableRow>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Image</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Title</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.length > 0 ? (
                  filteredData.map((news) => (
                    <TableRow key={news.id}>
                      <TableCell>
                        <img
                          src={news.image}
                          alt={news.title}
                          width="50"
                          height="50"
                          style={{ borderRadius: "8px" }}
                        />
                      </TableCell>
                      <TableCell>{news.title}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2} style={{ textAlign: "center", color: "red" }}>
                      No results found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default NewsAPI;
