
import React, { } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

const RolesPermissionsData = [
  { id: 1, name: "Rss only"},
  { id: 2, name: "view only" },
  // Add more visibility items as needed
];

const RolesPermissions = () => {
 

 

  

  
  return (
    <div style={{ width: "100%", marginTop: "50px", padding: "0" }}>
      <div style={{ margin: "16px 0", fontSize: "26px", paddingBottom: 10 }}>
        <Link to="/dashboard" style={{ color: "#000" }}>
          Dashboard
        </Link> / Roles List
      </div>
    
   

      {/* Table Section */}
      <div style={{ width: "100%", marginTop: "50px", padding: "0" }}>
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid #ddd",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h5" style={{ margin: "0", padding: "25px" }}>
           Roles List
          </Typography>
          <TableContainer component={Paper} style={{ width: "100%", margin: "0" }}>
            <Table aria-label="Roles list">
              <TableHead>
                <TableRow>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>ID</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Roles name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {
                  RolesPermissionsData.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.name}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default RolesPermissions;
