import React from 'react';
import { Box, Grid, TextField, Typography, Switch, Button } from '@mui/material';

export default function FacebookAds() {
  const [enabled, setEnabled] = React.useState(true);

  const handleSwitchChange = (event) => {
    setEnabled(event.target.checked);
  };

  return (
    <>
      <Box mt={1} display="flex" alignItems="center">
        <Switch
          checked={enabled}
          onChange={handleSwitchChange}
          color="primary"
        />
        <Typography variant="body1" sx={{ color: "#84818F", mr: 2 }}>
          Enable Facebook Ads
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {/* Facebook Ads Placement ID for Android */}
        <Grid item xs={12} md={6}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Facebook Ads Placement ID (Android)
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="861162831476588_1343353103257556"
          />
        </Grid>

        {/* Facebook Ads Placement ID for iOS */}
        <Grid item xs={12} md={6}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Facebook Ads Placement ID (iOS)
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Enter Facebook Ads Placement Id IOS"
          />
        </Grid>

        {/* Facebook Ads App Token */}
        <Grid item xs={12} md={6}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Facebook Ads App Token
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="ca-app-pub-3940256099942544/4411468910"
          />
        </Grid>

        {/* Facebook Ads Frequency */}
        <Grid item xs={12} md={6}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Facebook Ads Frequency
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="2"
          />
        </Grid>

        {/* Facebook Ads Interstitial ID for Android */}
        <Grid item xs={12} md={6}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Facebook Ads Interstitial ID (Android)
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="861162831476588_1343353036590896"
          />
        </Grid>

        {/* Facebook Ads Interstitial ID for iOS */}
        <Grid item xs={12} md={6}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Facebook Ads Interstitial ID (iOS)
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Facebook Ads Interstitial Id IOS"
          />
        </Grid>
      </Grid>
      {/* Save and Back Buttons */}
      <Box mt={4} display="flex" justifyContent="left">
        <Button variant="contained" sx={{ background: "#7367F0", marginRight: 3 }}>
          Save
        </Button>
        <Button variant="outlined" color="secondary">
          Back
        </Button>
      </Box>
    </>
  );
}
