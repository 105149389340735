import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

// Sample translation data
const translationData = [
  { id: 1, group: "General", keyword: "hello", value: "Hello", language: "English" },
  { id: 2, group: "General", keyword: "bye", value: "Goodbye", language: "English" },
  { id: 3, group: "Errors", keyword: "not_found", value: "Not Found", language: "Spanish" },
];

const Translation = () => {
  const [filters, setFilters] = useState({
    value: "",
    group: "",
    language: "",
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleSearch = () => {
    // Implement search functionality based on filters
  };

  const handleReset = () => {
    setFilters({
      value: "",
      group: "",
      language: "",
    });
  };

  // Filter data based on the value, group, and language
  const filteredData = translationData.filter((item) => {
    return (
      (filters.value === "" || item.value.toLowerCase().includes(filters.value.toLowerCase())) &&
      (filters.group === "" || item.group === filters.group) &&
      (filters.language === "" || item.language === filters.language)
    );
  });

  return (
    <div style={{ width: "100%", marginTop: "50px" }}>
      <div style={{ margin: "16px 0", fontSize: "26px", paddingBottom: 10 }}>
        <Link to="/dashboard" style={{ color: "#000" }}>
          Dashboard
        </Link> / Translation List
      </div>
      {/* Filter Section */}
      <div
        style={{
          marginBottom: "20px",
          padding: "18px",
          backgroundColor: "#fff",
          borderRadius: "8px",
        }}
      >
        <strong style={{ fontSize: 20 }}>Filter</strong>
        <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
          {/* Filter by Value */}
          <TextField
            label="Value"
            name="value"
            value={filters.value}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            style={{ flex: "1 1 24%", minWidth: "200px" }}
          />

          {/* Filter by Group */}
          <FormControl size="small" variant="outlined" style={{ flex: "1 1 24%", minWidth: "200px" }}>
            <InputLabel>Group</InputLabel>
            <Select
              label="Group"
              name="group"
              value={filters.group}
              onChange={handleFilterChange}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="General">General</MenuItem>
              <MenuItem value="Errors">Errors</MenuItem>
            </Select>
          </FormControl>

          {/* Filter by Language */}
          <FormControl size="small" variant="outlined" style={{ flex: "1 1 24%", minWidth: "200px" }}>
            <InputLabel>Language</InputLabel>
            <Select
              label="Language"
              name="language"
              value={filters.language}
              onChange={handleFilterChange}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="English">English</MenuItem>
              <MenuItem value="Spanish">Spanish</MenuItem>
              <MenuItem value="French">French</MenuItem>
            </Select>
          </FormControl>

          {/* Buttons */}
          <div style={{ flex: "1 1 24%", minWidth: "200px" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSearch}
              style={{ marginRight: 10 }}
            >
              Search
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </div>
      </div>

      {/* Table Section */}
      <div style={{ width: "100%", marginTop: "50px" }}>
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid #ddd",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h5" style={{ margin: "0", padding: "25px" }}>
            Translation List
          </Typography>
          <TableContainer component={Paper} style={{ width: "100%" }}>
            <Table aria-label="translation table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>ID</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Group</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Keyword</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.length > 0 ? (
                  filteredData.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.group}</TableCell>
                      <TableCell>{item.keyword}</TableCell>
                      <TableCell>{item.value}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} style={{ textAlign: "center", color: "red" }}>
                      No results found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default Translation;
