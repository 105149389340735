import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Dashboard as DashboardIcon,
  Category as CategoryIcon,
  Article as BlogIcon,
  RssFeed as RssFeedIcon,
  AdUnits as AdUnitsIcon,
  Announcement as NewsApiIcon,
  LiveTv as LiveNewsIcon,
  Settings as SettingsIcon,
  People as UsersIcon,
  Translate as TranslateIcon,
  ExpandLess as ExpandLessIcon, // Up arrow
  ChevronRight as ChevronRightIcon,
  Visibility as VisibilityIcon,
  Description as CmsIcon,
  Search as SearchIcon
} from "@mui/icons-material";

const Sidebar = () => {
  const [activeMenu, setActiveMenu] = useState("");

  const toggleMenu = (menu) => {
    if (activeMenu === menu) {
      setActiveMenu("");
    } else {
      setActiveMenu(menu);
    }
  };

  return (
    <div className="sidebar">
      <NavLink to="/dashboard" className={({ isActive }) => (isActive ? "active" : "")}>
        <DashboardIcon /> <span className="menu-text">Dashboard</span>
      </NavLink>
      <NavLink to="/categories" className={({ isActive }) => (isActive ? "active" : "")}>
        <CategoryIcon /> <span className="menu-text">Categories</span>
      </NavLink>
      <NavLink to="/blogs" className={({ isActive }) => (isActive ? "active" : "")}>
        <BlogIcon /> <span className="menu-text">Blogs</span>
      </NavLink>

      <div className={`menu ${activeMenu === "rss" ? "active" : ""}`}>
        <div onClick={() => toggleMenu("rss")} className="menu-title">
          <div className="menu-label">
            <RssFeedIcon /> <span className="menu-text">RSS</span>
          </div>
          <div className="menu-icon">
            {activeMenu === "rss" ? <ExpandLessIcon /> : <ChevronRightIcon />}
          </div>
        </div>
        {activeMenu === "rss" && (
          <ul>
            <li>
              <NavLink to="/rss-feeds" className={({ isActive }) => (isActive ? "active" : "")}>
                <span className="menu-text">RSS Feeds</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/rss-feed-items" className={({ isActive }) => (isActive ? "active" : "")}>
                <span className="menu-text">RSS Feed Items</span>
              </NavLink>
            </li>
          </ul>
        )}
      </div>

      <NavLink to="/ads" className={({ isActive }) => (isActive ? "active" : "")}>
        <AdUnitsIcon /> <span className="menu-text">Ads</span>
      </NavLink>
      <NavLink to="/news-api" className={({ isActive }) => (isActive ? "active" : "")}>
        <NewsApiIcon /> <span className="menu-text">News API</span>
      </NavLink>
      <NavLink to="/live-news" className={({ isActive }) => (isActive ? "active" : "")}>
        <LiveNewsIcon /> <span className="menu-text">Live News</span>
      </NavLink>
      <NavLink to="/e-papers" className={({ isActive }) => (isActive ? "active" : "")}>
        <BlogIcon /> <span className="menu-text">E-papers</span>
      </NavLink>
      <NavLink to="/visibility" className={({ isActive }) => (isActive ? "active" : "")}>
        <VisibilityIcon /> <span className="menu-text">Visibility</span>
      </NavLink>
      <NavLink to="/cms" className={({ isActive }) => (isActive ? "active" : "")}>
        <CmsIcon /> <span className="menu-text">CMS</span>
      </NavLink>
      <NavLink to="/search-log" className={({ isActive }) => (isActive ? "active" : "")}>
        <SearchIcon /> <span className="menu-text">Search Log</span>
      </NavLink>
      <div className={`menu ${activeMenu === "users" ? "active" : ""}`}>
        <div onClick={() => toggleMenu("users")} className="menu-title">
          <div className="menu-label">
            <UsersIcon /> <span className="menu-text">Users</span>
          </div>
          <div className="menu-icon">
            {activeMenu === "users" ? <ExpandLessIcon /> : <ChevronRightIcon />}
          </div>
        </div>
        {activeMenu === "users" && (
          <ul>
            <li>
              <NavLink to="/subadmins" className={({ isActive }) => (isActive ? "active" : "")}>
                <span className="menu-text">Subadmins</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/roles-permissions" className={({ isActive }) => (isActive ? "active" : "")}>
                <span className="menu-text">Roles & Permissions</span>
              </NavLink>
            </li>
          </ul>
        )}
      </div>

      <div className={`menu ${activeMenu === "settings" ? "active" : ""}`}>
        <div onClick={() => toggleMenu("settings")} className="menu-title">
          <div className="menu-label">
            <SettingsIcon /> <span className="menu-text">Settings</span>
          </div>
          <div className="menu-icon">
            {activeMenu === "settings" ? <ExpandLessIcon /> : <ChevronRightIcon />}
          </div>
        </div>
        {activeMenu === "settings" && (
          <ul>
            <li>
              <NavLink to="/all-settings" className={({ isActive }) => (isActive ? "active" : "")}>
                <span className="menu-text">All Settings</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/roles-permissions-settings" className={({ isActive }) => (isActive ? "active" : "")}>
                <span className="menu-text">Roles & Permissions</span>
              </NavLink>
            </li>
          </ul>
        )}
      </div>

      <div className={`menu ${activeMenu === "localization" ? "active" : ""}`}>
        <div onClick={() => toggleMenu("localization")} className="menu-title">
          <div className="menu-label">
            <TranslateIcon /> <span className="menu-text">Localization</span>
          </div>
          <div className="menu-icon">
            {activeMenu === "localization" ? <ExpandLessIcon /> : <ChevronRightIcon />}
          </div>
        </div>
        {activeMenu === "localization" && (
          <ul>
            <li>
              <NavLink to="/language" className={({ isActive }) => (isActive ? "active" : "")}>
                <span className="menu-text">Language</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/translation" className={({ isActive }) => (isActive ? "active" : "")}>
                <span className="menu-text">Translation</span>
              </NavLink>
            </li>
          </ul>
        )}
      </div>

      <style jsx="true">{`
        .sidebar {
          width: 250px;
          height: "auto";
          background: #fff;
          color: #000;
          padding: 20px;
          border-right: 1px solid #ddd;
          padding-top: 50px;
        }

        .sidebar a {
          display: flex;
          align-items: center;
          color: #000;
          padding: 10px;
          text-decoration: none;
        }

        .sidebar a.active {
          background: #7367f0;
          color: #fff;
          border-radius: 5px;
        }

        .menu {
          margin-top: 10px;
        }

        .menu-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          padding: 10px;
          font-size: 16px;
        }

        .menu-label {
          display: flex;
          align-items: center;
        }

        .menu-text {
          margin-left: 10px; /* Adjust the spacing between icon and text */
        }

        .menu-icon {
          display: flex;
          align-items: center;
        }

        .sidebar ul {
          padding-left: 20px;
          list-style: none;
        }

        .sidebar ul li {
          margin-top: 5px;
        }

        .sidebar ul li a {
          display: flex;
          align-items: center;
          color: #000;
          text-decoration: none;
          padding: 5px 0;
        }

        .sidebar ul li a.active {
          background: #7367f0;
          color: #fff;
          border-radius: 5px;
        }

        .sidebar ul li a.active::before {
          content: "•";
          color: #7367f0;
          font-size: 20px;
          margin-right: 8px;
        }

        .sidebar ul li a::before {
          content: "•";
          color: #aaa;
          font-size: 30px;
          margin-right: 8px;
        }
      `}</style>
    </div>
  );
};

export default Sidebar;
