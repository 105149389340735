import React, { useState } from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  TextField, Button, Select, MenuItem, FormControl, InputLabel, Typography
} from "@mui/material";
import { Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const blogsData = [
  {
    id: 1,
    image: "/download.jpeg",
    title: "Understanding React,Understanding React,Understanding React,Understanding React",
    visibility: "Public",
    views: 1200,
    createdDateTime: "2024-09-01T12:30:00",
    status: "Published",
    publishDate: "2024-09-01T10:00:00",
    modifiedDate: "2024-09-05T12:30:00",
    category: "Tech",
  },
  {
    id: 2,
    image: "/download.jpeg",
    title: "Learning Node.js,Learning Node.js,Learning Node.js,Learning Node.js,Learning Node.js",
    visibility: "Private",
    views: 800,
    createdDateTime: "2024-09-03T14:15:00",
    status: "Draft",
    publishDate: "2024-09-01T10:00:00",
    modifiedDate: "2024-09-02T12:30:00",
    category: "Tech",
  },
  // Add more rows as needed
];

const formatDateTime = (dateTimeString) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };
  return new Intl.DateTimeFormat("en-US", options).format(new Date(dateTimeString));
};

const Blogs = () => {
  const [filters, setFilters] = useState({
    title: "",
    category: "",
    visibility: "",
    status: "",
    type: "",
    publishDate: "",
    modifiedDate: "",
  });

  const [filteredBlogs, setFilteredBlogs] = useState(blogsData);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleSearch = () => {
    let filtered = blogsData.filter((blog) => {
      return (
        (filters.title === "" || blog.title.toLowerCase().includes(filters.title.toLowerCase())) &&
        (filters.category === "" || blog.category === filters.category) &&
        (filters.visibility === "" || blog.visibility === filters.visibility) &&
        (filters.status === "" || blog.status === filters.status) &&
        (filters.publishDate === "" || blog.publishDate.includes(filters.publishDate)) &&
        (filters.modifiedDate === "" || blog.modifiedDate.includes(filters.modifiedDate))
      );
    });
    setFilteredBlogs(filtered);
  };

  const handleReset = () => {
    setFilters({
      title: "",
      category: "",
      visibility: "",
      status: "",
      type: "",
      publishDate: "",
      modifiedDate: "",
    });
    setFilteredBlogs(blogsData);
  };

  return (
    <div style={{ width: "100%", marginTop: "50px", padding: "0" }}>
      <div style={{ margin: "16px 0", fontSize: "26px", paddingBottom: 10 }}>
        <Link to="/dashboard" style={{ color: "#000" }}>
          Dashboard
        </Link>{" "}
        / Blog List
      </div>

      {/* Filter Section */}
      <div
      style={{
        marginBottom: "20px",
        padding: "18px",
        backgroundColor: "#fff",
        borderRadius: "8px",
      }}
    >
      <strong style={{ fontSize: 20 }}>Filter</strong>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          marginTop: "10px",
        }}
      >
        {/* First Line */}
        <TextField
          label="Search Title"
          name="title"
          value={filters.title}
          onChange={handleFilterChange}
          variant="outlined"
          size="small"
          style={{ flex: "1 1 24%", minWidth: "200px" }}
        />

        <FormControl variant="outlined" size="small" style={{ flex: "1 1 24%", minWidth: "200px" }}>
          <InputLabel>Select Category</InputLabel>
          <Select
            name="category"
            value={filters.category}
            onChange={handleFilterChange}
            label="Select Category"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="Tech">Technology</MenuItem>
            <MenuItem value="Health">Health</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="outlined" size="small" style={{ flex: "1 1 24%", minWidth: "200px" }}>
          <InputLabel>Select Visibility</InputLabel>
          <Select
            name="visibility"
            value={filters.visibility}
            onChange={handleFilterChange}
            label="Select Visibility"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="Public">Public</MenuItem>
            <MenuItem value="Private">Private</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="outlined" size="small" style={{ flex: "1 1 24%", minWidth: "200px" }}>
          <InputLabel>Select Status</InputLabel>
          <Select
            name="status"
            value={filters.status}
            onChange={handleFilterChange}
            label="Select Status"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="Published">Published</MenuItem>
            <MenuItem value="Draft">Draft</MenuItem>
          </Select>
        </FormControl>

        {/* Second Line */}
        <FormControl variant="outlined" size="small" style={{ flex: "1 1 24%", minWidth: "200px" }}>
          <InputLabel>Select Type</InputLabel>
          <Select
            name="type"
            value={filters.type}
            onChange={handleFilterChange}
            label="Select Type"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="Type1">Type 1</MenuItem>
            <MenuItem value="Type2">Type 2</MenuItem>
          </Select>
        </FormControl>

        <TextField
          name="publishDate"
          value={filters.publishDate}
          onChange={handleFilterChange}
          variant="outlined"
          size="small"
          style={{ flex: "1 1 24%", minWidth: "200px" }}
          placeholder="YYYY-MM-DD HH:MM AM/PM"
        />

        <TextField
          name="modifiedDate"
          value={filters.modifiedDate}
          onChange={handleFilterChange}
          variant="outlined"
          size="small"
          style={{ flex: "1 1 24%", minWidth: "200px" }}
          placeholder="YYYY-MM-DD HH:MM AM/PM"
        />
<div  style={{ flex: "1 1 24%", minWidth: "200px" }}> 

<Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleSearch}
          style={{marginRight:10}}
        >
          Search
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={handleReset}
          
        >
          Reset
        </Button>
</div>
       
      </div>
    </div>

      {/* Table Section */}
      <div style={{ width: "100%", marginTop: "50px", padding: "0" }}>
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid #ddd",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h5" style={{ margin: "0", padding: "25px" }}>
            Most Viewed Blogs
          </Typography>
          <TableContainer
            component={Paper}
            style={{ width: "100%", margin: "0" }}
          >
            <Table aria-label="most viewed blogs">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}
                  >
                    Image
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}
                  >
                    Title
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}
                  >
                    Visibility
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}
                  >
                    Views
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}
                  >
                    Publish Date
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}
                  >
                    Modified Date
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {filteredBlogs.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8} style={{ textAlign: "center", color: "red" }}>
                      No results found
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredBlogs.map((blog, index) => (
                    <TableRow key={index}>
                      {/* Table Cells */}
                      <TableCell>
                        <img
                          src={blog.image}
                          alt={blog.title}
                          width="50"
                          height="50"
                          style={{ borderRadius: "8px" }}
                        />
                      </TableCell>
                      <TableCell>{blog.title} <span
                          style={{
                            backgroundColor:"#7367F0",
                            color: "white",
                            padding: "4px 8px",
                            borderRadius: "4px",
                          }}
                        >
                          post
                        </span></TableCell>
                      <TableCell>
                        <span style={{ backgroundColor: "#7367F0", color: "white", padding: "4px 8px", borderRadius: "4px" }}>{blog.visibility}</span>
                      </TableCell>
                      <TableCell>{blog.views}</TableCell>
                      <TableCell>{formatDateTime(blog.publishDate)}</TableCell>
                      <TableCell>{formatDateTime(blog.modifiedDate)}</TableCell>
                      <TableCell>
                        <span
                          style={{
                            backgroundColor: blog.status === "Published" ? "green" : "red",
                            color: "white",
                            padding: "4px 8px",
                            borderRadius: "4px",
                          }}
                        >
                          {blog.status}
                        </span>
                      </TableCell>
                      <TableCell>
                        <MoreVertIcon />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
