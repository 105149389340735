// src/components/Layout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar'; // Adjust the import path if necessary

const Layout = () => {
  return (
    <div style={{ display: 'flex', minHeight: '100vh',backgroundColor: 'lightgrey' }}>
      <Sidebar />
      <main style={{ flex: 1, padding: '20px', backgroundColor: 'lightgrey' }}>
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
