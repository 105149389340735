import React from 'react';
import { Button, TextField, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();

  const handleSignIn = () => {
    // Handle authentication logic here
    navigate('/dashboard');
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        sx={{ flexDirection: 'column' }}
      >
        <Box
          width={300}
          p={3}
          boxShadow={3}
          textAlign="center"
          sx={{ borderRadius: 3 }}
        >
          <Typography variant="h5" mb={2}>
            Sign In to your account
          </Typography>
          <Typography mb={2} sx={{ color: 'lightgray' }}>
            Enter your email & password to login
          </Typography>

          {/* Email Label */}
          <Typography variant="body1" align="left">
            Email Address
          </Typography>
          <TextField 
            fullWidth 
            margin="normal"
            placeholder="Email" // Optional: Add a placeholder 
          />

          {/* Password Label */}
          <Typography variant="body1" align="left">
            Password
          </Typography>
          <TextField 
            type="password" 
            fullWidth 
            margin="normal"
            placeholder="Password" // Optional: Add a placeholder 
          />

          {/* Sign In Button */}
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#7367F0',
              '&:hover': {
                backgroundColor: '#5b50d6',
              },
              mt: 2,
            }}
            fullWidth
            onClick={handleSignIn}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Login;
