import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";
const subadminData = [
  {
    id: 1,
    image: "/download.jpeg",
    name: "John Doe",
    email: "john.doe@example.com",
    status: "active",
    createdAt: "2024-08-01",
  },
  {
    id: 2,
    image: "/download.jpeg",
    name: "Jane Smith",
    email: "jane.smith@example.com",
    status: "inactive",
    createdAt: "2024-07-25",
  },
  // Add more subadmins as needed
];

const SubAdmins = () => {
  const [filters, setFilters] = useState({
    keyword: "",
    status: "",
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleSearch = () => {
    // Implement search functionality based on the keyword and status
  };

  const handleReset = () => {
    setFilters({
      keyword: "",
      status: "",
    });
  };

  // Filter data based on the keyword and status
  const filteredData = subadminData.filter((item) => {
    return (
      (filters.keyword === "" || item.name.toLowerCase().includes(filters.keyword.toLowerCase())) &&
      (filters.status === "" || item.status === filters.status)
    );
  });

  return (
    <div style={{ width: "100%", marginTop: "50px" }}>
      <div style={{ margin: "16px 0", fontSize: "26px", paddingBottom: 10 }}>
        <Link to="/dashboard" style={{ color: "#000" }}>
          Dashboard
        </Link> / Subadmin List
      </div>
      {/* Filter Section */}
      <div
        style={{
          marginBottom: "20px",
          padding: "18px",
          backgroundColor: "#fff",
          borderRadius: "8px",
        }}
      >
        <strong style={{ fontSize: 20 }}>Filter</strong>
        <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
          {/* Search Keywords */}
          <TextField
            label="Search Keywords"
            name="keyword"
            value={filters.keyword}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            style={{ flex: "1 1 24%", minWidth: "200px" }}
          />

          {/* Select Status */}
          <FormControl size="small" variant="outlined" style={{ flex: "1 1 24%", minWidth: "200px" }}>
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>

          {/* Buttons */}
          <div style={{ flex: "1 1 24%", minWidth: "200px" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSearch}
              style={{ marginRight: 10 }}
            >
              Search
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </div>
      </div>

      {/* Table Section */}
      <div style={{ width: "100%", marginTop: "50px" }}>
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid #ddd",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h5" style={{ margin: "0", padding: "25px" }}>
            Subadmin List
          </Typography>
          <TableContainer component={Paper} style={{ width: "100%" }}>
            <Table aria-label="subadmin table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>ID</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Image</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Name</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Email</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.length > 0 ? (
                  filteredData.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>
                        <Avatar alt={item.name} src={item.image} />
                      </TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>{item.createdAt}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} style={{ textAlign: "center", color: "red" }}>
                      No results found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default SubAdmins;
