import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
const searchLogData = [
  { id: 1, log: "React tutorial", count: 5, searchDate: "2024-09-06" },
  { id: 2, log: "Material-UI components", count: 10, searchDate: "2024-09-05" },
  { id: 3, log: "JavaScript array methods", count: 7, searchDate: "2024-09-04" },
  // Add more logs as needed
];

const SearchLog = () => {
  const [filters, setFilters] = useState({
    keyword: "",
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleSearch = () => {
    // Implement search functionality based on the keyword
  };

  const handleReset = () => {
    setFilters({
      keyword: "",
    });
  };

  // Filter data based on the keyword filter
  const filteredData = searchLogData.filter((item) =>
    filters.keyword === "" || item.log.toLowerCase().includes(filters.keyword.toLowerCase())
  );

  return (
    <div style={{ width: "100%", marginTop: "50px",minHeight: "110vh" }}>

      <div style={{ margin: "16px 0", fontSize: "26px", paddingBottom: 10 }}>
        <Link to="/dashboard" style={{ color: "#000" }}>
          Dashboard
        </Link> / Search Log List
      </div>
      {/* Filter Section */}
      <div
        style={{
          marginBottom: "20px",
          padding: "18px",
          backgroundColor: "#fff",
          borderRadius: "8px",
        }}
      >
        <strong style={{ fontSize: 20 }}>Filter</strong>
        <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
          {/* Search Keywords */}
          <TextField
            label="Search Keywords"
            name="keyword"
            value={filters.keyword}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            style={{ flex: "1 1 24%", minWidth: "200px" }}
          />

          {/* Buttons */}
          <div style={{ flex: "1 1 24%", minWidth: "200px" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSearch}
              style={{ marginRight: 10 }}
            >
              Search
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </div>
      </div>

      {/* Table Section */}
      <div style={{ width: "100%", marginTop: "50px" }}>
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid #ddd",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h5" style={{ margin: "0", padding: "25px" }}>
            Search Log List
          </Typography>
          <TableContainer component={Paper} style={{ width: "100%" }}>
            <Table aria-label="search log table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>ID</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Search Log</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Count</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Search Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.length > 0 ? (
                  filteredData.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.log}</TableCell>
                      <TableCell>{item.count}</TableCell>
                      <TableCell>{item.searchDate}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} style={{ textAlign: "center", color: "red" }}>
                      No results found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default SearchLog;
