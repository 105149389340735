import React from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';

export default function AppSettings() {
  return (
    <>
      <Grid container spacing={2}>
        {/* First row of fields */}
        <Grid item xs={12} md={4}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            App Name
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Good news"
          />
        </Grid>
      </Grid>

      {/* Logo Upload Section */}
      <Box display="flex" flexDirection="column" gap={2} mb={2} sx={{ marginTop: 6 }}>
        {/* App Logo */}
        <Box display="flex" flexDirection="column">
          <Typography variant="body1">App Logo</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <img
              src="/download.jpeg"
              alt="logo"
              width="70"
              height="70"
              style={{ borderRadius: '8px' }}
            />
            <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
              <Button variant="contained" sx={{ backgroundColor: "#1F09FF" }} component="label">
                Upload App Logo
                <input type="file" hidden />
              </Button>
              <Typography sx={{ fontSize: 16 }}>Resolution: 512x512</Typography>
            </Box>
          </Box>
        </Box>

        {/* App splash screen */}
        <Box display="flex" flexDirection="column">
          <Typography variant="body1">App splash screen</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <img
              src="/download.jpeg"
              alt="admin logo"
              width="70"
              height="70"
              style={{ borderRadius: '8px' }}
            />
            <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
              <Button variant="contained" sx={{ backgroundColor: "#1F09FF" }} component="label">
                Upload App splash screen
                <input type="file" hidden />
              </Button>
              <Typography sx={{ fontSize: 16 }}>Resolution: 512x512</Typography>
            </Box>
          </Box>
        </Box>

        {/* Rectangular App Logo */}
        <Box display="flex" flexDirection="column">
          <Typography variant="body1">Rectangular App Logo</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <img
              src="/download.jpeg"
              alt="favicon"
              width="70"
              height="70"
              style={{ borderRadius: '8px' }}
            />
            <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
              <Button variant="contained" sx={{ backgroundColor: "#1F09FF" }} component="label">
                Upload Rectangular App Logo
                <input type="file" hidden />
              </Button>
              <Typography sx={{ fontSize: 16 }}>Resolution: 379x128</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Color Picker Section */}
      <Box display="flex" flexDirection="column" gap={2} mt={4}>
        <Box display="flex" flexDirection="row" gap={4}>
          <Box display="flex" flexDirection="column">
          <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom:2}}>
  
  Primary Color
  <Box sx={{ width: 24, height: 24, borderRadius: '4px', backgroundColor: '#333' }} />
</Typography>
            <TextField
              fullWidth
              type="color"
              defaultValue="#333" // Default color value
              variant="outlined"
              size="small"
              sx={{width:300}}
            />
          </Box>

          <Box display="flex" flexDirection="column">
          <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', gap: 1  , marginBottom:2}}>
  
  Secondary Color
  <Box sx={{ width: 24, height: 24, borderRadius: '4px', backgroundColor: '#333' }} />
</Typography>
            <TextField
              fullWidth
              type="color"
              defaultValue="#333" // Default color value
              variant="outlined"
              size="small"
              sx={{width:300}}
            />
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2} sx={{marginTop:2}}>
        {/* First row of fields */}
        <Grid item xs={12} md={4}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Support Email
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Support@gmail.com"
          />
        </Grid>
      </Grid>
        {/* Save and Back Buttons */}
        <Box mt={4} display="flex" justifyContent="left">
        <Button variant="contained" sx={{background: "#7367F0", marginRight:3}}>
          Save
        </Button>
        <Button variant="outlined" color="secondary">
          Back
        </Button>
      </Box>
    </>
  );
}
