import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography
} from "@mui/material";
import { Link } from "react-router-dom";
const rssFeedData = [
  {
    id: 1,
    category: "Tech",
    language: "English",
    name: "React Basics",
    url: "https://example.com/react-basics",
  },
  {
    id: 2,
    category: "Health",
    language: "Spanish",
    name: "Healthy Eating",
    url: "https://example.com/healthy-eating",
  },
  // Add more rows as needed
];

const RSSFeeds = () => {
  const [filters, setFilters] = useState({
    keyword: "",
    status: "",
    category: "",
    language: "",
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleSearch = () => {
    // Implement search functionality
  };

  const handleReset = () => {
    setFilters({
      keyword: "",
      status: "",
      category: "",
      language: "",
    });
  };

  return (
    <div style={{ width: "100%", marginTop: "50px", padding: "0" }}>
      <div style={{ margin: "16px 0 ", fontSize: "26px", paddingBottom: 10 }}>
        <Link to="/dashboard" style={{ color: "#000" }}>
          Dashboard
        </Link> / RSS Feed Items
      </div>
      {/* Filter Section */}
      <div
        style={{
          marginBottom: "20px",
          padding: "18px",
          backgroundColor: "#fff",
          borderRadius: "8px",
        }}
      >
        <strong style={{ fontSize: 20 }}>Filter</strong>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            marginTop: "10px",
          }}
        >
          {/* First Line */}
          <TextField
            label="Search Keyword"
            name="keyword"
            value={filters.keyword}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            style={{ flex: "1 1 24%", minWidth: "200px" }}
          />

          <FormControl variant="outlined" size="small" style={{ flex: "1 1 24%", minWidth: "200px" }}>
            <InputLabel>Select Status</InputLabel>
            <Select
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              label="Select Status"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" size="small" style={{ flex: "1 1 24%", minWidth: "200px" }}>
            <InputLabel>Select Category</InputLabel>
            <Select
              name="category"
              value={filters.category}
              onChange={handleFilterChange}
              label="Select Category"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Tech">Tech</MenuItem>
              <MenuItem value="Health">Health</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" size="small" style={{ flex: "1 1 24%", minWidth: "200px" }}>
            <InputLabel>Select Language</InputLabel>
            <Select
              name="language"
              value={filters.language}
              onChange={handleFilterChange}
              label="Select Language"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="English">English</MenuItem>
              <MenuItem value="Spanish">Spanish</MenuItem>
            </Select>
          </FormControl>

          {/* Buttons */}
          <div style={{ flex: "1 1 24%", minWidth: "200px" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSearch}
              style={{ marginRight: 10 }}
            >
              Search
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </div>
      </div>

      {/* Table Section */}
      <div style={{ width: "100%", marginTop: "50px", padding: "0" }}>
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid #ddd",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h5" style={{ margin: "0", padding: "25px" }}>
            RSS Feed Items
          </Typography>
          <TableContainer component={Paper} style={{ width: "100%", margin: "0" }}>
            <Table aria-label="rss feed items">
              <TableHead>
                <TableRow>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>ID</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Category</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Language</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Name</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>URL</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rssFeedData.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.category}</TableCell>
                    <TableCell>{item.language}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      <a href={item.url} target="_blank" rel="noopener noreferrer">{item.url}</a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default RSSFeeds;
