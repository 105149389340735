import React from "react";
import { Routes, Route,Navigate } from "react-router-dom";
import Dashboard from "../components/pages/Dashboard";
import Categories from "../components/pages/Categories";
import Blogs from "../components/pages/Blogs";
import RSSFeeds from "../components/pages/RSS/RSSFeeds";
import RSSFeedItems from "../components/pages/RSS/RSSFeedItems";
import Ads from "../components/pages/Ads";
import NewsAPI from "../components/pages/NewsAPI";
import LiveNews from "../components/pages/LiveNews";
import EPapers from "../components/pages/E-Papers";
import Visibility from "../components/pages/Visibility";
import CMS from "../components/pages/CMS";
import SearchLog from "../components/pages/SearchLog";
import SubAdmins from "../components/pages/Users/SubAdmins";
import RolesPermissions from "../components/pages/Users/RolesPermissions";
import AllSettings from "../components/pages/Settings/AllSettings";
import RolesPermissionsSettings from "../components/pages/Settings/RolesPermissions";
import Language from "../components/pages/Localization/Language";
import Translation from "../components/pages/Localization/Translation";
import Layout from "../components/Layout";
import Login from "../components/Login";
import Page404 from "../components/Page404";
const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login/>} />
      <Route element={<Layout />}>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/categories" element={<Categories />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/rss-feeds" element={<RSSFeeds />} />
      <Route path="/rss-feed-items" element={<RSSFeedItems />} />
      <Route path="/ads" element={<Ads />} />
      <Route path="/news-api" element={<NewsAPI />} />
      <Route path="/live-news" element={<LiveNews />} />
      <Route path="/e-papers" element={<EPapers />} />
      <Route path="/visibility" element={<Visibility />} />
      <Route path="/cms" element={<CMS />} />
      <Route path="/search-log" element={<SearchLog />} />
      <Route path="/subadmins" element={<SubAdmins />} />
      <Route path="/roles-permissions" element={<RolesPermissions />} />
      <Route path="/all-settings" element={<AllSettings />} />
      <Route path="/roles-permissions-settings" element={<RolesPermissionsSettings />} />
      <Route path="/language" element={<Language />} />
      <Route path="/translation" element={<Translation />} />
      </Route>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="*" element={<Page404/>} />
    </Routes>
  );
};

export default AppRoutes;
