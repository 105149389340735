import React from 'react'
import { Box, Button, Switch, Typography } from '@mui/material';

export default function LiveNews() {
  const [enabled, setEnabled] = React.useState(true);

  const handleSwitchChange = (event) => {
    setEnabled(event.target.checked);
  };
  return (
    <>
      {/* Logo Upload Section */}
      <Box display="flex" flexDirection="column" gap={2} mb={2} sx={{ marginTop: 6 }}>

        <Box display="flex" flexDirection="column" gap={2} mb={2}>

          {/* Live News Image */}
          <Box display="flex" flexDirection="column">
            <Typography variant="body1">Live News Image</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <img
                src="/download.jpeg"
                alt="logo"
                width="70"
                height="70"
                style={{ borderRadius: '8px' }}
              />
              <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
                <Button variant="contained" sx={{ backgroundColor: "#1F09FF" }} component="label">
                  Live News Image
                  <input type="file" hidden />
                </Button>
                <Typography sx={{ fontSize: 16 }}>Resolution: 512x512</Typography>
              </Box>
            </Box>
            <Box mt={1} display="flex" alignItems="center">
              <Switch
                checked={enabled}
                onChange={handleSwitchChange}
                color="primary"
              />
              <Typography variant="body1" sx={{ color: "#84818F", mr: 2 }}>
                Live news status
              </Typography>
            </Box>
          </Box>
          {/* Epapers Image */}
          <Box display="flex" flexDirection="column">
            <Typography variant="body1">Epapers Image</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <img
                src="/download.jpeg"
                alt="logo"
                width="70"
                height="70"
                style={{ borderRadius: '8px' }}
              />
              <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
                <Button variant="contained" sx={{ backgroundColor: "#1F09FF" }} component="label">
                  Epapers Image
                  <input type="file" hidden />
                </Button>
                <Typography sx={{ fontSize: 16 }}>Resolution: 512x512</Typography>
              </Box>
            </Box>
            <Box mt={1} display="flex" alignItems="center">
              <Switch
                checked={enabled}
                onChange={handleSwitchChange}
                color="primary"
              />
              <Typography variant="body1" sx={{ color: "#84818F", mr: 2 }}>
                Epapers status
              </Typography>
            </Box>
          </Box>



        </Box>


      </Box>
      {/* Save and Back Buttons */}
      <Box mt={4} display="flex" justifyContent="left">
        <Button variant="contained" sx={{ background: "#7367F0", marginRight: 3 }}>
          Save
        </Button>
        <Button variant="outlined" color="secondary">
          Back
        </Button>
      </Box>
    </>

  )
}
