import React, { useState } from 'react';
import { Grid, Card, Typography, Box, TextField, IconButton,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@mui/material';
import BlogIcon from '@mui/icons-material/Article';
import QuoteIcon from '@mui/icons-material/FormatQuote';
import CategoryIcon from '@mui/icons-material/Category';
import UserIcon from '@mui/icons-material/People';
import SearchIcon from '@mui/icons-material/Search';
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { PieChart, Pie, Cell, ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';

const Dashboard = () => {
  // Example data
  const totalData = {
    blogs: 120,
    quotes: 80,
    categories: 15,
    users: 350,
  };

  const cardData = [
    { title: 'Total Blogs', count: totalData.blogs, icon: <BlogIcon fontSize="large" />, bgColor: '#DEE4FF' },
    { title: 'Total Quotes', count: totalData.quotes, icon: <QuoteIcon fontSize="large" />, bgColor: '#FFDEE4' },
    { title: 'Total Categories', count: totalData.categories, icon: <CategoryIcon fontSize="large" />, bgColor: '#E4FFDE' },
    { title: 'Total Users', count: totalData.users, icon: <UserIcon fontSize="large" />, bgColor: '#E4E4FF' },
  ];

  // Pie chart data
  const pieData = [
    { name: 'Email', value: 55 },
    { name: 'Google', value: 45 },
  ];

  // Bar chart state and data
  const [dateRange, setDateRange] = useState('2024-07-01 00:00 to 2024-07-18 00:00');
  const [barChartData, setBarChartData] = useState([
    { date: '2024-07-01', users: 4 },
    { date: '2024-07-02', users: 2 },
    { date: '2024-07-03', users: 6 },
    { date: '2024-07-04', users: 4 },
    { date: '2024-07-05', users: 9 },
    { date: '2024-07-06', users: 6 },
    { date: '2024-07-07', users: 7 },
    { date: '2024-07-08', users: 8 },
    { date: '2024-07-09', users: 13 },
    { date: '2024-07-10', users: 10 },
    { date: '2024-07-11', users: 11 },
    { date: '2024-07-12', users: 16 },
    { date: '2024-07-13', users: 13 },
    { date: '2024-07-14', users: 14 },
    { date: '2024-07-15', users: 15 },
    { date: '2024-07-16', users: 16 },
    { date: '2024-07-17', users: 17 },
    { date: '2024-07-18', users: 18 },
    // Initial mock data
  ]);
  const blogsData = [
    {
      image: "/download.jpeg",
      title: "Sample Blog Title 1",
      visibility: "Public",
      views: 1500,
      createdDateTime: "2023-09-01 10:00 AM",
      status: "Active",
    },
    {
      image: "/download.jpeg",
      title: "Sample Blog Title 2",
      visibility: "Private",
      views: 250,
      createdDateTime: "2023-08-25 05:30 PM",
      status: "Inactive",
    },
    {
      image: "/download.jpeg",
      title: "Sample Blog Title 3",
      visibility: "Private",
      views: 250,
      createdDateTime: "2023-08-25 05:30 PM",
      status: "Inactive",
    },
    // Add more rows as needed
  ];
  const categoriesData = [
    {
      id: 1,
      image: "/download.jpeg",
      mainCategory: "Technology",
      name: "Web Development",
      totalBlogs: 120,
    },
    {
      id: 2,
      image: "/download.jpeg",
      mainCategory: "Health",
      name: "Fitness",
      totalBlogs: 80,
    },
    {
      id: 3,
      image: "/download.jpeg",
      mainCategory: "Car",
      name: "Fitness",
      totalBlogs: 80,
    },
    // Add more rows as needed
  ];
  const fetchBarChartData = () => {
    // Replace this with a real API call
    const mockData = [
      { date: '2024-07-01', users: 1 },
      { date: '2024-08-02', users: 2 },
      { date: '2024-07-03', users: 3 },
      { date: '2024-07-04', users: 4 },
      { date: '2024-07-05', users: 5 },
      { date: '2024-07-06', users: 6 },
      { date: '2024-07-07', users: 7 },
      { date: '2024-07-08', users: 8 },
      { date: '2024-07-09', users: 9 },
      { date: '2024-07-10', users: 10 },
      { date: '2024-07-11', users: 11 },
      { date: '2024-07-12', users: 12 },
      { date: '2024-07-13', users: 13 },
      { date: '2024-07-14', users: 14 },
      { date: '2024-07-15', users: 15 },
      { date: '2024-07-16', users: 16 },
      { date: '2024-07-17', users: 17 },
      { date: '2024-07-18', users: 18 },
      // More mock data
    ];
    setBarChartData(mockData);
  };

  const formatDate = (date) => {
    // Format the date as needed; this is just an example
    return new Date(date).toLocaleDateString();
  };

  return (
    <div style={{ padding: '20px' }}>
      <Grid container spacing={1}>
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', padding: '10px' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '2px',
                    backgroundColor: card.bgColor,
                    borderRadius: '20%',
                    width: '40px',
                    height: '40px',
                    marginRight: '6px',
                  }}
                >
                  {card.icon}
                </Box>
                <Typography variant="h4" align="center">
                  {card.count}
                </Typography>
              </Box>
              <Typography variant="subtitle1" align="left" sx={{ marginTop: '5px' }}>
                {card.title}
              </Typography>
            </Card>
          </Grid>
        ))}
        {/* Grid container for Pie and Bar charts side-by-side */}
        <Grid container spacing={3} marginTop={8}>
          {/* Pie chart card */}
          <Grid item xs={12} md={4}>
            <Card sx={{ padding: '20px', height: '80%' }}>
              <Typography variant="h6" align="left" sx={{ marginBottom: '10px' }}>
                Users by Login Methods
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                  width: '100%',
                }}
              >
                <ResponsiveContainer width="100%" height="80%">
                  <PieChart>
                    <Pie
                      data={pieData}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={100}
                      fill="#8884d8"
                      paddingAngle={5}
                      dataKey="value"
                    >
                      <Cell key="cell-1" fill="#03A9F4" />
                      <Cell key="cell-2" fill="red" />
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <Box sx={{ width: '100%', marginTop: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column', marginBottom: '5px' }}>
                    <Typography variant="body2">Email</Typography>
                    <Box sx={{ width: '65px', height: '15px', backgroundColor: '#03A9F4', borderRadius: '10%', marginRight: '10px' }} />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column', marginBottom: '5px' }}>
                    <Typography variant="body2">Google</Typography>
                    <Box sx={{ width: '65px', height: '15px', backgroundColor: 'red', borderRadius: '10%', marginRight: '10px' }} />
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
          {/* Bar chart card */}
          <Grid item xs={12} md={8}>
            <Card sx={{ padding: '10px', height: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <Typography variant="h6" align="left" sx={{ flexGrow: 1 }}>
                User Statistics
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
            sx={{width:"40%"}}
                  value={dateRange}
                  onChange={(e) => setDateRange(e.target.value)}
                />
                <Box sx={{backgroundColor:"blue" , display:"flex",alignItems:"center"}}>
                <IconButton edge="center" onClick={fetchBarChartData} >
                          <SearchIcon  sx={{ color: 'white' }}/>
                        </IconButton>
                </Box>
                
              </Box>
              <Box sx={{ height: 400 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={barChartData}
                    margin={{ top: 20, right: 30, bottom: 30, left: 0 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="date"
                      tickFormatter={formatDate}
                      angle={-45}
                      textAnchor="end"
                      height={60}
                    />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="users" fill="#03A9F4" radius={[10, 10, 0, 0]} />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ width: "100%", marginTop: "50px", padding: "0" }}>
      <div
        style={{
          backgroundColor: "white",
          border: "1px solid #ddd",
          borderRadius: "8px",
        }}
      >
        <h2 style={{ margin: "0", padding: "25px" }}>Most Viewed Blogs</h2>
        <TableContainer component={Paper} style={{ width: "100%", margin: "0" }}>
          <Table aria-label="most viewed blogs">
            <TableHead>
              <TableRow>
                <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Image</TableCell>
                <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Title</TableCell>
                <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Visibility</TableCell>
                <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Views</TableCell>
                <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Created Date Time</TableCell>
                <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Status</TableCell>
                <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {blogsData.map((blog, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <img src={blog.image} alt={blog.title} width="50" height="50" />
                  </TableCell>
                  <TableCell>{blog.title}</TableCell>
                  <TableCell>{blog.visibility}</TableCell>
                  <TableCell>{blog.views}</TableCell>
                  <TableCell>{blog.createdDateTime}</TableCell>
                  <TableCell>{blog.status}</TableCell>
                  <TableCell>
                    <MoreVertIcon />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
    <div style={{ width: "100%", marginTop: "20px", padding: "0" }}>
      <div
        style={{
          backgroundColor: "#f9f9f9",
          border: "1px solid #ddd",
          borderRadius: "8px",
        }}
      >
        <h2 style={{ margin: "0", padding: "25px" }}>Most Selected Categories</h2>
        <TableContainer component={Paper} style={{ width: "100%", margin: "0" }}>
          <Table aria-label="most selected categories">
            <TableHead>
              <TableRow>
                <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>ID</TableCell>
                <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Image</TableCell>
                <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Main Category</TableCell>
                <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Name</TableCell>
                <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Total Blogs</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categoriesData.map((category, index) => (
                <TableRow key={index}>
                  <TableCell>{category.id}</TableCell>
                  <TableCell>
                    <img
                      src={category.image}
                      alt={category.name}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%", // Circle styling
                      }}
                    />
                  </TableCell>
                  <TableCell>{category.mainCategory}</TableCell>
                  <TableCell>{category.name}</TableCell>
                  <TableCell>{category.totalBlogs}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
    </div>
  );
};

export default Dashboard;
