
import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  TextField, Button, Select, MenuItem, FormControl, InputLabel, Typography
} from '@mui/material';
import { Link } from "react-router-dom";

const socialMediaData = [
  { id: 1, name: 'Facebook', uri: 'https://facebook.com', status: 'Active' },
  { id: 2, name: 'Twitter', uri: 'https://twitter.com', status: 'Inactive' },
  { id: 3, name: 'Instagram', uri: 'https://instagram.com', status: 'Active' },
  // Add more rows as needed
];

const RolesPermissions = () => {
  const [filters, setFilters] = useState({
    keyword: '',
    status: ''
  });

  const [filteredData, setFilteredData] = useState(socialMediaData);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleSearch = () => {
    let filtered = socialMediaData.filter((item) => {
      return (
        (filters.keyword === '' || item.name.toLowerCase().includes(filters.keyword.toLowerCase())) &&
        (filters.status === '' || item.status === filters.status)
      );
    });
    setFilteredData(filtered);
  };

  const handleReset = () => {
    setFilters({
      keyword: '',
      status: ''
    });
    setFilteredData(socialMediaData);
  };

  return (
    <div style={{ width: '100%', marginTop: '50px', padding: '0' }}>
      <div style={{ margin: "16px 0", fontSize: "26px", paddingBottom: 10 }}>
    <Link to="/dashboard" style={{ color: "#000" }}>
      Dashboard
    </Link> / Social Media Link List
  </div>
      {/* Filter Section */}
      <div
        style={{
          marginBottom: '20px',
          padding: '18px',
          backgroundColor: '#fff',
          borderRadius: '8px',
        }}
      >
        <strong style={{ fontSize: 20 }}>Filter</strong>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            marginTop: '10px',
          }}
        >
          <TextField
            label="Search Keyword"
            name="keyword"
            value={filters.keyword}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            style={{ flex: '1 1 24%', minWidth: '200px' }}
          />

          <FormControl
            variant="outlined"
            size="small"
            style={{ flex: '1 1 24%', minWidth: '200px' }}
          >
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              label="Status"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </Select>
          </FormControl>

          <div style={{ flex: '1 1 24%', minWidth: '200px' }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSearch}
              style={{ marginRight: 10 }}
            >
              Search
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </div>
      </div>

      {/* Table Section */}
      <div style={{ width: '100%', marginTop: '50px', padding: '0' }}>
        <div
          style={{
            backgroundColor: 'white',
            border: '1px solid #ddd',
            borderRadius: '8px',
          }}
        >
          <Typography variant="h5" style={{ margin: '0', padding: '25px' }}>
          Social Media Link List
          </Typography>
          <TableContainer
            component={Paper}
            style={{ width: '100%', margin: '0' }}
          >
            <Table aria-label="social media list">
              <TableHead>
                <TableRow>
                  <TableCell style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                    ID
                  </TableCell>
                  <TableCell style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                    Name
                  </TableCell>
                  <TableCell style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                    URI
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      <a href={item.uri} target="_blank" rel="noopener noreferrer">
                        {item.uri}
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default RolesPermissions;
