import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography
} from "@mui/material";
import { Link } from "react-router-dom";

const rssFeedData = [
 
  // Add more rows as needed
];

const RSSFeedsItems = () => {
  const [filters, setFilters] = useState({
    category: "",
    source: "",
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleSearch = () => {
    // Implement search functionality based on filters
  };

  const handleReset = () => {
    setFilters({
      category: "",
      source: "",
    });
  };

  // Filter data based on the selected filters
  const filteredData = rssFeedData.filter(item => 
    (filters.category === "" || item.category.includes(filters.category)) &&
    (filters.source === "" || item.source === filters.source)
  );

  return (
    <div style={{ width: "100%", marginTop: "50px", padding: "0" }}>
      <div style={{ margin: "16px 0", fontSize: "26px", paddingBottom: 10 }}>
        <Link to="/dashboard" style={{ color: "#000" }}>
          Dashboard
        </Link> / RSS Feed List
      </div>
      {/* Filter Section */}
      <div
        style={{
          marginBottom: "20px",
          padding: "18px",
          backgroundColor: "#fff",
          borderRadius: "8px",
        }}
      >
        <strong style={{ fontSize: 20 }}>Filter</strong>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            marginTop: "10px",
          }}
        >
          {/* Search Category */}
          <TextField
            label="Search Category"
            name="category"
            value={filters.category}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            style={{ flex: "1 1 24%", minWidth: "200px" }}
          />

          {/* Select Source */}
          <FormControl variant="outlined" size="small" style={{ flex: "1 1 24%", minWidth: "200px" }}>
            <InputLabel>Select Source</InputLabel>
            <Select
              name="source"
              value={filters.source}
              onChange={handleFilterChange}
              label="Select Source"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="TechBlog">TechBlog</MenuItem>
              <MenuItem value="HealthDigest">HealthDigest</MenuItem>
              {/* Add more sources as needed */}
            </Select>
          </FormControl>

          {/* Buttons */}
          <div style={{ flex: "1 1 24%", minWidth: "200px" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSearch}
              style={{ marginRight: 10 }}
            >
              Search
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </div>
      </div>

      {/* Table Section */}
      <div style={{ width: "100%", marginTop: "50px", padding: "0" }}>
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid #ddd",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h5" style={{ margin: "0", padding: "25px" }}>
            RSS Feed Item List
          </Typography>
          <TableContainer component={Paper} style={{ width: "100%", margin: "0" }}>
            <Table aria-label="rss feed list">
              <TableHead>
                <TableRow>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Image</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Title</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.length > 0 ? (
                  filteredData.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>
                        <img
                          src={item.image}
                          alt={item.title}
                          width="50"
                          height="50"
                          style={{ borderRadius: "8px" }}
                        />
                      </TableCell>
                      <TableCell>{item.title}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2} style={{ textAlign: "center", color: "red" }}>
                      No results found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default RSSFeedsItems;
