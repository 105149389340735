import React from 'react';
import { Box, Grid, TextField, Typography,Button } from '@mui/material';

export default function EmailSettings() {
  return (
    <>
      <Grid container spacing={2}>
        {/* Mailer Field */}
        <Grid item xs={12} md={4}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Mailer
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="SMTP"
          />
        </Grid>

        {/* Host Field */}
        <Grid item xs={12} md={4}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Host
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="smpt.googlemail.com"
          />
        </Grid>

        {/* Port Field */}
        <Grid item xs={12} md={4}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Port
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="457"
          />
        </Grid>

        {/* Username Field */}
        <Grid item xs={12} md={4}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Username
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="socailtechno@gmail.com"
          />
        </Grid>

        {/* Password Field */}
        <Grid item xs={12} md={4}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Password
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            type="password"
            placeholder="jbhgvhjbjhfyuvjkbyu"
          />
        </Grid>

        {/* Encryption Field */}
        <Grid item xs={12} md={4}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            Encryption
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="ssl"
          />
        </Grid>

        {/* From Name Field */}
        <Grid item xs={12} md={4}>
          <Typography variant="body1" gutterBottom sx={{ color: "#84818F" }}>
            From Name
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="incite"
          />
        </Grid>
      </Grid>
       {/* Save and Back Buttons */}
       <Box mt={4} display="flex" justifyContent="left">
        <Button variant="contained" sx={{background: "#7367F0", marginRight:3}}>
          Save
        </Button>
        <Button variant="outlined" color="secondary">
          Back
        </Button>
      </Box>
    </>
  );
}
