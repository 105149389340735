import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

// Sample data
const languageData = [
  { id: 1, name: "English", code: "EN", position: 1, isDefault: true, status: "active" },
  { id: 2, name: "Spanish", code: "ES", position: 2, isDefault: false, status: "inactive" },
  { id: 3, name: "French", code: "FR", position: 3, isDefault: false, status: "active" },
];

const Language = () => {
  const [filters, setFilters] = useState({
    keyword: "",
    status: "",
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleSearch = () => {
    // Implement search functionality based on the keyword and status
  };

  const handleReset = () => {
    setFilters({
      keyword: "",
      status: "",
    });
  };

  // Filter data based on the keyword and status
  const filteredData = languageData.filter((item) => {
    return (
      (filters.keyword === "" || item.name.toLowerCase().includes(filters.keyword.toLowerCase())) &&
      (filters.status === "" || item.status === filters.status)
    );
  });

  return (
    <div style={{ width: "100%", marginTop: "50px" }}>
     
      <div style={{ margin: "16px 0", fontSize: "26px", paddingBottom: 10 }}>
        <Link to="/dashboard" style={{ color: "#000" }}>
          Dashboard
        </Link> / Language List
      </div>
      {/* Filter Section */}
      <div
        style={{
          marginBottom: "20px",
          padding: "18px",
          backgroundColor: "#fff",
          borderRadius: "8px",
        }}
      >
        <strong style={{ fontSize: 20 }}>Filter</strong>
        <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
          {/* Search Name */}
          <TextField
            label="Search Name"
            name="keyword"
            value={filters.keyword}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            style={{ flex: "1 1 24%", minWidth: "200px" }}
          />

          {/* Select Status */}
          <FormControl size="small" variant="outlined" style={{ flex: "1 1 24%", minWidth: "200px" }}>
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>

          {/* Buttons */}
          <div style={{ flex: "1 1 24%", minWidth: "200px" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSearch}
              style={{ marginRight: 10 }}
            >
              Search
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </div>
      </div>

      {/* Table Section */}
      <div style={{ width: "100%", marginTop: "50px" }}>
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid #ddd",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h5" style={{ margin: "0", padding: "25px" }}>
            Language List
          </Typography>
          <TableContainer component={Paper} style={{ width: "100%" }}>
            <Table aria-label="language table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>ID</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Name</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Code</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Position</TableCell>
                  <TableCell style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>Default</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.length > 0 ? (
                  filteredData.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.code}</TableCell>
                      <TableCell>{item.position}</TableCell>
                      <TableCell>
                        <span
                          style={{
                            backgroundColor: item.isDefault ? "green" : "red",
                            color:"#fff",
                            padding:5,
                            fontSize:12,
                            borderRadius:3
                          }}
                        >
                          {item.isDefault ? "Yes" : "No"}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} style={{ textAlign: "center", color: "red" }}>
                      No results found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default Language;
